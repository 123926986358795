<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="人员姓名">
          <el-input
            size="small"
            clearable
            v-model="searchForm.userNames"
            placeholder="请输入人员姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件号码">
          <el-input
            size="small"
            clearable
            v-model="searchForm.userCardId"
            placeholder="请输入证件号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属机构">
          <el-cascader
            size="small"
            clearable
            
            v-model="orgaIdArr"
            :options="copanyListInfo"
            popper-class="custom-cascader"
            filterable
            :props="{
              checkStrictly: true,
              expandTrigger: 'hover',
              value: 'orgaPathIds',
            }"
            @change="cascaderChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select
            v-model="searchForm.userAuditState"
            size="small"
            class="block-select"
            placeholder="请选择审核状态"
          >
            <el-option
              v-for="item in userAuditStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :total="total"
      noimport="true"
      noexport="true"
      title="操作人员"
      isdel="false"
      issh="false"
      @selectionChange="selectionChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @tableexamine="tableexamine"
      @tableDel="tableDel"
      @tableAdd="tableAdd"
    >
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column
        prop="userName"
        label="人员姓名"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="userMobile"
        label="手机号码"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="userCardId"
        label="证件号码"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="userSex"
        label="人员性别"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.userSex == 0">男</span>
          <span v-if="scope.row.userSex == 1">女</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="userAuditState"
        label="审核状态"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.userAuditState == 0"
            style="color: rgb(32, 150, 71)"
            >已审核</span
          >
          <span v-if="scope.row.userAuditState == 1">已拒绝</span>
          <span v-if="scope.row.userAuditState == 2" style="color: red"
            >待审核</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="orgaName" align="center" label="所属机构">
      </el-table-column>
      <el-table-column prop="userHeadpic" align="center" label="头像">
        <template slot-scope="scope">
          <div v-if="scope.row.userHeadpic">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.userHeadpic"
              fit="contain"
              @click="showImg(scope.row.userHeadpic)"
            ></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" align="center" label="创建时间">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span class="btn-icon" @click="editItem(scope.row, 0)">详情</span>
          <span class="btn-icon" @click="delItem(scope.row)">删除</span>
          <span class="btn-icon" @click="addEntourage(scope.row, 1)"
            >随行人员</span
          >
          <!-- <span class="btn-icon" @click="examine(scope.row)">审核</span> -->
        </template>
      </el-table-column>
      <audio
        controls="controls"
        hidden
        ref="audio"
        src="../../../assets/audio/S-100205-100205-B001FD3C (mp3cut.net).mp3"
      ></audio>
    </custom-tables>
    <edit-operator
      :visible.sync="showDialog"
      :title="dialogTitle"
      :item="item"
      :type="addType"
      @close="showDialog = false"
      @done="editDone"
    ></edit-operator>
    <EditExamine
      :visible.sync="showDialog2"
      :title="dialogTitle2"
      :item2="item2"
      :type="addType"
      @close="showDialog2 = false"
      @done="editDone"
    ></EditExamine>
  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import EditOperator from "../../../components/page/basic/EditOperator";
import EditExamine from "../../../components/page/basic/EditExamine.vue";
import { organizationCascader } from "../../../utils/tool";

export default {
  name: "operator",
  components: { EditOperator, SearchHead, CustomTables, EditExamine },
  data() {
    return {
      item2: "",
      dialogTitle: "新增公司",
      showDialog: false,
      showDialog2: false,
      dialogTitle2: "审核",
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      item: {},
      orgaIdArr: [],
      copanyListInfo: [],
      addType: 0,
      userid: [],
      userAuditStateList: [
        { label: "已审核", value: 0 },
        { label: "已拒绝", value: 1 },
        { label: "待审核", value: 2 },
      ],
      countdown: 5,
      timer: null,
      isList: [],
    };
  },
  mounted() {
    this.init()
    this.getCopanyInfo();
    this.getOperInfo();
    document.addEventListener("mousemove", this.resetTimer);
    this.startTimer();
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.resetTimer);
    this.clearTimer();
  },
  methods: {
    init() {
      //刚打开网站时弹框引导用户点击
      this.$alert("允许网站播放声音", "提示", {
        confirmButtonText: "确定",
        showClose: false,
        callback: (action) => {
          this.isPlay = true;
          // this.play();
        },
      });
    },
    play() {
      //用户点击后，即可播放
      if (this.isPlay) {
        this.$refs.audio.currentTime = 0; //从头开始播放提示音
        this.$refs.audio.play(); //播放
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
        
          if (this.isList>0) {
            console.log(2222);
            this.$refs.audio.currentTime = 0; //从头开始播放提示音
            this.$refs.audio.play(); //播放
          }
          this.getOperInfo();
        }
      }, 3000);
    },
    resetTimer() {
      this.countdown = 5;
    },
    clearTimer() {
      clearInterval(this.timer);
    },
    // refreshPage() {
    //   location.reload();
    // },
    getCopanyInfo() {
      basic.getOrganizationTree({ orgaPid: 0 }).then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data);
        this.copanyListInfo = data;
      });
    },
    getOperInfo() {
      this.isList = [];
      basic.getOperatorListByPage(this.searchForm).then((res) => {
        this.tableData = res.data.list;
        console.log(this.tableData, " this.tableData");
        res.data.list.map((item) => {
          if (item.userAuditState == 2) {
            this.isList.push(item.userAuditState);
          }
        });
        console.log(this.isList, "this.isList");
        this.total = res.data.total;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getOperInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getOperInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.orgaIdArr = [];
      this.getOperInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getOperInfo();
    },
    tableDel() {},
    examine() {
      this.dialogTitle2 = "审核";
      this.showDialog2 = true;
    },
    tableexamine() {
      console.log(this.userid, "this.userid");
      if (this.userid.length > 0) {
        basic.check({ userIds: this.userid }).then((res) => {
          this.$message({
            message: "审核成功",
            type: "success",
          });
          this.getOperInfo();
        });
      } else {
        this.$message({
          message: "请先勾选审核人员",
          type: "error",
        });
      }
    },
    addEntourage(row) {
      console.log(row);
      // this.item = {
      //   userId: row.userId
      // }
      // this.addType = 1
      this.dialogTitle2 = "随行人员";
      this.showDialog2 = true;
      this.item2 = row.userId;
    },
    tableAdd() {
      this.item = {};
      this.addType = 0;
      this.dialogTitle = "新增操作人员";
      this.showDialog = true;
      // this.tableData=[]
    },
    selectionChange(val) {
      this.userid = [];
      console.log(val);
      val.map((item) => {
        this.userid.push(item.userId);
      });
    },
    editItem(item) {
      // 根据operId 获取人员详情
      basic.getOperatorById({ userIds: item.userId }).then((res) => {
        if (!res.data) {
          this.$message({
            message: "详情获取失败",
            type: "warning",
          });
          return;
        }
        this.addType = 0;
        this.item = res.data;
        this.dialogTitle = "编辑操作人员";
        this.showDialog = true;
      });
    },
    delItem(item) {
      this.$confirm(`此操作将永久删除该操作人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          basic.deleteOperator({ userIds: item.userId }).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getOperInfo();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    cascaderChange(val) {
      let valLen = val.length;
      this.searchForm.orgaPathIds = val[valLen - 1];
    },
    editDone() {
      this.getOperInfo();
      this.showDialog = false;
    },
    showImg(url) {
      let html = `<div class="img-alert"><img src="${url}"/></div>`;
      this.$alert(html, "图片", {
        dangerouslyUseHTMLString: true,
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
