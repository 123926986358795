<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    isFooter="false"
    @close="colseDialog"
 
  >
  <custom-tables :data="tableData" :total="total"
                   noimport="true"
                   noexport="true"
                   title="随行人员"
                   isdel="false"
                   pagesc="fasle"
                   @selectionChange="selectionChange"
                   @handleCurrentChange="handleCurrentChange"
                   @handleSizeChange="handleSizeChange"
               
                   @tableDel="tableDel" @tableAdd="tableAdd">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="userName"
          label="人员姓名"
          align="center"
          width="180">
      </el-table-column>
      <el-table-column
          prop="userMobile"
          label="证件号"
          align="center"
          width="180">
      </el-table-column>
      <el-table-column
          prop="userSex"
          label="人员性别"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.userSex == 0">男</span>
          <span v-if="scope.row.userSex == 1">女</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
          prop="userAuditState"
          label="审核状态"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.userAuditState == 0" style="color: rgb(32, 150, 71);">已审核</span>
          <span v-if="scope.row.userAuditState == 1" >已拒绝</span>
          <span v-if="scope.row.userAuditState == 2" style="color: red;">待审核</span>
        </template>
      </el-table-column> -->
      <el-table-column
          prop="orgaName"
          align="center"
          label="所属机构">
      </el-table-column>
      <!-- <el-table-column
          prop="userHeadpic"
          align="center"
          label="头像">
        <template slot-scope="scope">
          <div v-if="scope.row.userHeadpic">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.userHeadpic"
                fit="contain" @click="showImg(scope.row.userHeadpic)"></el-image>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
          prop="createTime"
          align="center"
          label="联系电话">
      </el-table-column>
      <el-table-column
          prop="createTime"
          align="center"
          label="备注说明">
      </el-table-column>
      <el-table-column label="操作" align="center" >
        <template slot-scope="scope">
          <!-- <span class="btn-icon" @click="editItem(scope.row,0)">详情</span> -->
          <span class="btn-icon" @click="delItem(scope.row)">删除</span>
          <!-- <span class="btn-icon" @click="addEntourage(scope.row, 1)">随行人员</span> -->
          <!-- <span class="btn-icon" @click="examine(scope.row)">审核</span> -->
        </template>
      </el-table-column>
    </custom-tables>
    <EditFollowUser :visible.sync="showDialog" :title="dialogTitle" :item="item" :type="addType" @close="showDialog= false" @done="editDone" ></EditFollowUser>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import CustomTables from "../../../components/common/CustomTables";
import basic from "../../../api/modules/basic";
import { organizationCascader } from "../../../utils/tool";
import { sexState } from "../../../config/dataStatus";
import config from "../../../api/config";
import EditFollowUser from '../basic/EditFollowUser.vue'
export default {
  name: "EditOperator",
  components: { CustomDialog,CustomTables,EditFollowUser },
  props: ["visible", "title",  "type",'item2'],
  data() {
    return {
      radio: "1",
        dialogTitle:'新增公司',
      showDialog: false,
      showDialog2:false,
      dialogTitle2:'审核',
      total:0,
      tableData:[],
      searchForm:{
        currPage: 1,
        pageSize:10
      },
      item:{},
      orgaIdArr: [],
      copanyListInfo:[],
      addType:0,
      userid:[],
      userAuditStateList:[{label:'已审核',value:0},{label:'已拒绝',value:1},{label:'待审核',value:2}],
      countdown: 5,
      timer: null
      
    };
  },
  mounted() {
 
  },
watch:{
  item2(newVal,oldVal){
    if(newVal){
      this. getOperInfo()
    }
  }
},
  methods: {
   
   
  
    colseDialog() {
      this.$emit("close");
    },
    cascaderChange(val) {
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    uploadChange() {},
    uploadSuccess(val) {
      if (val.code == 200) {
        this.editForm.userHeadpic = val.message;
        this.$forceUpdate();
      }
    },

    getCopanyInfo(){
      basic.getOrganizationTree({orgaPid:0}).then(res =>{
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data)
        this.copanyListInfo = data
      })
    },
    getOperInfo(){
      let param = {
        userIds:this.items
      }
      basic.getFollowUserList(this.item2).then(res =>{
        this.tableData = res.data
        console.log( this.tableData,' this.tableData');
        // this.total = res.data.total
      })
    },
    handleCurrentChange(pageIndex){
      this.searchForm.currPage = pageIndex
      this.getOperInfo()
    },
    handleSizeChange(pageSize){
      this.searchForm.currPage = 1
      this.searchForm.pageSize = pageSize
      this.getOperInfo()
    },
    headReset(){
      this.searchForm = {
        currPage:1,
        pageSize:10
      }
      this.orgaIdArr = []
      this.getOperInfo()
    },
    headSearch(){
      this.searchForm.currPage = 1
      this.searchForm.pageSize = 10
      this.getOperInfo()
    },
    tableDel(){

    },
   
    
    addEntourage(row){
      // this.item = {
      //   userId: row.userId
      // }
      // this.addType = 1
      this.dialogTitle2 = '随行人员'
      this.showDialog2 = true
    },
    tableAdd(){
      this.item = this.item2
      this.addType = 0
      this.dialogTitle = '新增随行人员'
      this.showDialog = true
      // this.tableData=[]
    },
    selectionChange(val){
      this.userid=[]
      console.log(val)
      val.map(item=>{
        this.userid.push(item.userId)
      })
     
    },
    editItem(item){
      // 根据operId 获取人员详情
      basic.getOperatorById({userIds:item.userId}).then(res =>{
        if (!res.data) {
          this.$message({
            message: '详情获取失败',
            type: 'warning'
          });
          return
        }
        this.addType = 0
        this.item = res.data
        this.dialogTitle = '编辑操作人员'
        this.showDialog = true
      })

    },
    delItem(item){
      let params={
        userIds:item.userId,
        mainId:item.mainId
      }
      this.$confirm(`此操作将永久删除该人员, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        basic.deleteFollowUser(params).then(res =>{
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getOperInfo()
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    cascaderChange(val){
      let valLen = val.length
      this.searchForm.orgaPathIds = val[valLen-1]
    },
    editDone(){
      this.getOperInfo()
      this.showDialog = false
    },
  


  },
};
</script>

<style scoped lang="less">
.edit-form {
  padding: 20px;
}

.person-certificate {
  border-top: 1px solid #f0f0f0;
  padding: 20px;
  position: relative;

  .person-head {
    display: flex;
    justify-content: space-between;

    .title {
    }
  }

  .body-item {
    border: 1px solid #f0f0f0;
    margin-top: 10px;
  }

  .body-item-del {
    //position: absolute;
    text-align: right;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
</style>
