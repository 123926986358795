<template>
  <custom-dialog :visible.sync="visible" :title="title" @close="colseDialog" @submit="submit">
    <el-form :model="editForm" label-width="80px" class="edit-form" :rules="rules" ref="editOperatorForm">
      <el-row :gutter="160">
        <el-col :span="12">
          <el-form-item label="所属机构" prop="projid">
            <el-select v-model="editForm.projid" size="small" class="block-select" placeholder="请选择所属机构" filterable>
              <el-option
                  v-for="item in projectList"
                  :key="item.projId"
                  
                  :label="item.projName"
                  :value="item.projId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="用户姓名" prop="userName">
            <el-input
                size="small"
                v-model="editForm.userName"
                placeholder="请输入用户姓名">
            </el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="出生日期" prop="operBirthday">-->
<!--            <el-date-picker-->
<!--                size="small"-->
<!--                v-model="editForm.operBirthday"-->
<!--                type="date"-->
<!--                format="yyyy-MM-dd"-->
<!--                value-format="yyyy-MM-dd HH:mm:ss"-->
<!--                placeholder="选择日期">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="12">
          <el-form-item label="身份证号" prop="userCardId">
            <el-input
                size="small"
                v-model="editForm.userCardId"
                placeholder="请输入身份证号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="userSex">
            <el-select
                class="block-select"
                size="small"
                v-model="editForm.userSex"
                placeholder="请选择性别">
              <el-option
                  v-for="item in sexState"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号码" prop="userMobile">
            <el-input
                size="small"
                v-model="editForm.userMobile"
                placeholder="请输入手机号码">
            </el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <el-form-item label="职务" prop="job">-->
<!--            <el-input-->
<!--                size="small"-->
<!--                v-model="editForm.job"-->
<!--                placeholder="请输入职务">-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
      <el-form-item label="用户照片" prop="userHeadpic">
        <el-upload
            class="avatar-uploader"
            :action="`${uploadUrl}File/upload`"
            :data="{ type: 1 }"
            :show-file-list="false"
            :on-change="uploadChange"
            :on-success="uploadSuccess">
          <img
              v-if="editForm.userHeadpic"
              :src="editForm.userHeadpic"
              class="avatar"/>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="备注" prop="userNote">
        <el-input
            size="small"
            type="textarea"
            v-model="editForm.userNote"
            placeholder="备注">
        </el-input>
      </el-form-item>
    </el-form>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import basic from "../../../api/modules/basic";
import {organizationCascader} from "../../../utils/tool";
import {sexState} from "../../../config/dataStatus";
import config from "../../../api/config";

export default {
  name: "EditOperator",
  components: {CustomDialog},
  props: ["visible", "title", "item","type"],
  data() {
    return {
      editForm: {
        userName:'',
        userCardId:'',
        userSex:'',
        userMobile:'',
        userHeadpic:'',
      },
      rules: {
        projid: [{required: true, message: "请选择所属机构", trigger: "change"}],
        userName: [{required: true, message: "请输入姓名", trigger: "blur"}],
        userCardId: [{required: true, message: "请输入身份证号", trigger: "change"},],
        userSex: [{required: true, message: "请选择性别", trigger: "change"}],
        userMobile: [{required: true, message: "请输入手机号码", trigger: "blur"},],
      },
      copanyListInfo: [],
      sexState: sexState,
      uploadUrl: config.uploadUrl,
      typeList: [],
      projectList:[]
    };
  },
  mounted() {
   this.getProjectList()
  },
  watch: {
    item: {
      handler(val) {
        this.getDetail(val)
      },
      immediate: false,
      deep: true,
    },
  },
  methods: {
    getProjectList() {
      basic.getProjectList().then(res => {
        this.projectList = res.data
      })
    },
    getDetail(val){
      if(val.userId){
        // 编辑的逻辑
        this.editForm = {...val}
        this.editForm.userIds=val.userId
        if(val.orgaPathIds && val.orgaPathIds.match('-')){
          let orgaIdArr = val.orgaPathIds.split('-').map(item => Number(item))
          let orgaLevel  = this.$store.state.common.user.orgaLevel
          if(orgaLevel == 0){
            this.editForm.orgaIdArr = orgaIdArr
          }else if(orgaLevel == 1){
            let a = orgaIdArr.shift()
            this.editForm.orgaIdArr = orgaIdArr
          }else {
            this.editForm.orgaIdArr = [orgaIdArr[2]]
          }
        }
      }else {
        // 新增
        this.editForm = {
          userName:'',
          userCardId:'',
          userSex:'',
          userMobile:'',
          userHeadpic:'',
        }
      }
      this.resetForm()
    },
    getCopanyInfo() {
      basic.getOrganizationTree({orgaPid: 0}).then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data);
        this.copanyListInfo = data;
      });
    },
    submit() {
      this.$refs.editOperatorForm.validate((valid) => {
        if (valid) {
          if (this.editForm.userId) {
            basic.UpdateMeetingUserInfo(this.editForm).then((res) => {
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
                this.$emit("done");
              });
          } else {
            // 新增人员信息
            basic.addOperator(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit("close");
    },
    cascaderChange(val) {
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    uploadChange() {
    },
    uploadSuccess(val) {
      if (val.code == 200) {
        this.editForm.userHeadpic = val.message;
        this.$forceUpdate();
      }
    },
    resetForm(){
      if( this.$refs.editOperatorForm){
        this.$refs.editOperatorForm.clearValidate()
        this.$refs.editOperatorForm.resetFields()
      }
    }
  },
};
</script>

<style scoped lang="less">
.edit-form {
  padding: 20px;
}

.person-certificate {
  border-top: 1px solid #f0f0f0;
  padding: 20px;
  position: relative;

  .person-head {
    display: flex;
    justify-content: space-between;

    .title {
    }
  }

  .body-item {
    border: 1px solid #f0f0f0;
    margin-top: 10px;
  }

  .body-item-del {
    //position: absolute;
    text-align: right;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}
</style>